<template>
  <v-main align="center">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-card class="elevation-12">
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t("settings.title") }}
                <span></span>
              </v-toolbar-title>
            </v-toolbar>
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Setting",
};
</script>

<style>
</style>